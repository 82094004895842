import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import vodafone from '../assest/vodafone.png'
import paypal from '../assest/paypal.png'
import { useTranslation } from 'react-i18next';

function Contact () {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
        const inputField = document.querySelectorAll('.myInput');

        inputField.forEach((input) => {
            input.addEventListener('input', function () {
                if (input.value) {
                    // إذا كان هناك نص في الحقل
                    input.style.color = '#fff'; // تعيين اللون إلى الأخضر
                } else {
                    // إذا كان الحقل فارغًا
                    input.style.color = 'initial'; // إعادة تعيين اللون إلى القيمة الافتراضية
                }
            });
        })
    }, [])

    return (
        <section className='contact' id='contact'>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "اتصل بنا" : "Contact Us"}</span>
                    <p>{i18n.language == "ar" ? "اتصل بنا" : "Contact Us"}</p>
                </h2>

                {/* <div className="map" style={{ margin: "auto", textAlign: "center", paddingTop: "1rem" }}>
                    <h3 style={{ color: "var(--main-color-opacity-100)" }}>{i18n.language == "ar" ? "العنوان: " : "Location: "}</h3>
                    <p>{i18n.language == "ar" ? "200 شارع جسر السويس - امام فندق كونكورد السلام – القاهرة – مصر" : "200 Gesr El Suez Street - In Front Of Concorde El Salam Hotel - Cairo - Egypt"}</p>
                </div> */}

                <div className="contact-content">
                    <form action={`${process.env.REACT_APP_PROJECTS_API}components/insert_message.php`} method='post' >
                        <div className="input-box">
                            <div className="input-field" style={{ width: "100%" }}>
                                <div className="icon"><i class="fa fa-user"></i></div>
                                <input className='myInput' name='name' type="text" placeholder={i18n.language == "ar" ? "الاسم بالكامل" : "Name"} required />
                            </div>
                        </div>

                        <div className="input-box">
                            <div className="input-field">
                                <div className="icon"><i class="fa fa-phone"></i></div>
                                <input className='myInput' name='phone' type="phone" placeholder={i18n.language == "ar" ? "رقم الهاتف" : "Phone"} required />
                            </div>
                            <div className="input-field">
                                <div className="icon email"><i class="fa fa-envelope"></i></div>
                                <input className='myInput' name='email' type="email" placeholder={i18n.language == "ar" ? "البريد الالكتروني" : "Email"} required />
                            </div>
                        </div>

                        <div className="textarea-field">
                            <div className="icon message"><i class="fa fa-comment"></i></div>
                            <textarea className='myInput' name="message" cols={30} rows={10} placeholder={i18n.language == "ar" ? "الرسالة" : "Message"} required></textarea>
                        </div>

                        <div className="btn-box btns">
                            <button type='submit' name='send' class="button type1">
                                <span class="btn-txt"><a>{i18n.language == "ar" ? "ارسال" : "Send"}</a></span>
                            </button>
                        </div>
                    </form>



                    <div className="contact-social">
                        {/* <span>
                            <a href='mailto:info@ritalmedia.com?subject=Rital Media Website' target='_blank' class="btn-sosial" >
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-envelope"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span> */}
                        <span>
                            <a href='https://www.facebook.com/Hackwapisd' target='_blank' class="btn-sosial" >
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-facebook-f"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>
                        <span>
                            <a href='https://www.instagram.com/hackwapi' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-instagram"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>
                        <span>
                            <a href='https://twitter.com/hackwapi' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-twitter"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>
                        <span>
                            <a href='https://api.whatsapp.com/send/?phone=249926552272 ' target='_blank' class="btn-sosial">
                                <span class="svgContainer">
                                </span>
                                <i class="fa fa-whatsapp"></i>
                                <span class="BG-social"></span>
                            </a>
                        </span>

                    </div>


                </div>
            </div>
        </section>
    )
}

export default Contact
